<template>
  <div>
    <h2 class="pageTitle pt-5 mb-8">予診票{{ createOrEdit[newOrEdit] }}</h2>
    <div class="mx-6">
      <v-radio-group v-model="how2make" row>
        <v-radio
          :label="'Formから' + createOrEdit[newOrEdit]"
          value="form"
        ></v-radio>
        <v-radio
          :label="'Jsonから' + createOrEdit[newOrEdit]"
          value="json"
        ></v-radio>
      </v-radio-group>
      <v-textarea
        v-model="textForm"
        label="予診票"
        outlined
        height="400"
        :error="err"
        :error-messages="errMessage"
      ></v-textarea>
      <v-row justify="end" class="mr-2 pt-2">
        <v-btn color="primary" @click="makePushed"
          >予診票{{ createOrUpdate[newOrEdit] }}</v-btn
        >
      </v-row>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";

export default {
  components: {},
  data: () => ({
    newOrEdit: "new",
    id: "",
    how2make: "json", // どのようにアンケートを作るか
    err: false,
    errMessage: "",
    textForm: "",
    createOrEdit: { new: "作成", edit: "編集" },
    createOrUpdate: { new: "作成", edit: "更新" },
  }),
  created() {
    if (this.$router.currentRoute.query.id) {
      this.id = this.$router.currentRoute.query.id;
      firebase
        .firestore()
        .collection("pre_exam_data")
        .doc(this.id)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.newOrEdit = "edit";
            this.textForm = JSON.stringify(doc.data());
          }
        });
    }
    if (this.$router.currentRoute.query.return) {
      this.textForm = JSON.stringify(this.$store.getters.prediction_data);
    }
  },

  methods: {
    makePushed() {
      //   if (this.$refs.question_form.validate()) {
      //     this.$store.commit("prediction_data", {
      //       title: this.title,
      //       questions: this.questions,
      //       updated: new Date()
      //     });
      //     if (this.newOrEdit == "new") {
      //       this.$router.push({
      //         name: "confirmPrediction"
      //       });
      //     } else {
      //       this.$router.push({
      //         name: "confirmPrediction",
      //         query: { id: this.id }
      //       });
      //     }
      //   }
      const jsonForm = this.checkFormatJson(this.textForm);
      if (jsonForm && this.checkFormatArr(jsonForm)) {
        if (this.newOrEdit === "new") {
          firebase
            .firestore()
            .collection("pre_exam_data")
            .add({
              title: jsonForm.title,
              questions: jsonForm.questions,
              updated: new Date(),
            })
            .then(() => {
              this.$router.push({ name: "completePrediction" });
            });
        } else {
          firebase
            .firestore()
            .collection("pre_exam_data")
            .doc(this.id)
            .update({
              title: jsonForm.title,
              questions: jsonForm.questions,
              updated: new Date(),
            })
            .then(() => {
              this.$router.push({
                name: "completePrediction",
                query: { edit: "edit" },
              });
            });
        }
      }
    },
    checkFormatJson(textForm) {
      let jsonForm = {};
      if (textForm[0] !== "{" || textForm[textForm.length - 1] !== "}") {
        this.err = true;
        this.errMessage = "json形式で入力して下さい";
        return false;
      }
      try {
        jsonForm = JSON.parse(textForm);
      } catch (e) {
        this.err = true;
        this.errMessage = "jsonの形式が正しくありません";
      }
      ["title", "questions"].forEach((ele) => {
        if (!jsonForm[ele]) {
          this.err = true;
          this.errMessage = `key「${ele}」が存在しません`;
        }
      });
      return jsonForm;
    },
    checkFormatArr(jsonForm) {
      let formatCheck = typeof jsonForm.title === "string";
      if (!formatCheck) {
        this.err = true;
        this.errMessage = "titleは文字列で入力してください";
      }
      formatCheck = Array.isArray(jsonForm.questions);
      if (!formatCheck) {
        this.err = true;
        this.errMessage = "質問は配列で記入してください";
      } else {
        jsonForm.questions.forEach((question) => {
          ["text", "memo"].forEach((key) => {
            formatCheck = key in question && formatCheck;
            if (!(key in question)) {
              this.err = true;
              this.errMessage = `質問事項に、key「${key}」が存在しません`;
            }
          });
          if (formatCheck && !question.text) {
            this.err = true;
            this.errMessage = "質問事項を記入してください";
          }
          if (formatCheck && question.memo > 10) {
            this.err = true;
            this.errMessage = "メモは10文字以内で記入してください";
          }
        });
      }
      return formatCheck;
    },
  },
  watch: {
    how2make() {
      if (this.id) {
        this.$router.push({
          name: "makePrediction",
          query: { id: this.id },
        });
      } else {
        this.$router.push({ name: "makePrediction" });
      }
    },
  },
  filters: {},
};
</script>
<style>
.pageTitle {
  border-bottom: solid 3px rgba(0, 0, 0, 0.87);
}
</style>
